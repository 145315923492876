import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import classNames from 'classnames';
import {FieldContainer, Select, Label, FieldErrors} from './components';

const SelectField = ({
  floatingLabel, selectName, value, label, optValue, onChange, onBlur, options, htmlFor, errors, required,
  containerProps, selectProps, labelProps, errorsContainerProps, className, showFloatingLabel, disabled
}) => {
  const hasError = !!errors.length;
  const htmlClasses = classNames('floating-label-select position-relative', className);

  return (
    <FieldContainer
      {...containerProps}
      value={value}
      className={htmlClasses}
      floatingLabel={floatingLabel}
      showFloatingLabel={showFloatingLabel}
      hasError={hasError}
    >
      <Select
        {...selectProps}
        id={htmlFor}
        name={selectName}
        value={value}
        label={`${optValue || label}${required ? '*' : ''}`}
        hasError={hasError}
        onChange={onChange}
        onBlur={onBlur}
        options={options}
        disabled={disabled}
      />
      <span className="icon icon-triangle-sm-down" aria-hidden="true"></span>
      <Label {...labelProps} htmlFor={htmlFor}>{`${label}${required ? '*' : ''}`}</Label>
      {hasError && <FieldErrors {...errorsContainerProps} errors={errors} />}
    </FieldContainer>
  );
};

SelectField.propTypes = {
  floatingLabel: PropTypes.bool.isRequired,
  showFloatingLabel: PropTypes.bool.isRequired,
  className: PropTypes.string,
  selectName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  optValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  errors: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
  htmlFor: PropTypes.string,
  containerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  selectProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  labelProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  errorsContainerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  })
};

SelectField.defaultProps = {
  floatingLabel: true,
  showFloatingLabel: false,
  inputName: '',
  value: '',
  label: '',
  disabled: false,
  required: false,
  errors: [],
  // Not required properties
  htmlFor: null,
  placeholder: null,
  containerProps: {},
  inputProps: {},
  labelProps: {},
  errorsContainerProps: {}
};

export default memo(SelectField);
